import React, { useState, useEffect, useContext } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'
import { Global } from '@emotion/react'
import { GrCreditCard } from 'react-icons/gr'
import { FaSimCard } from 'react-icons/fa'

import GlobalStyles from 'styles/GlobalStyles'
import { useAuth0 } from 'utils/react-auth0-spa'
import UserContext from 'context/user/UserContext'
import SubscriptionContext from 'context/subscription/SubscriptionContext'
import Loading from 'components/Loading'
import {
  Body,
  AllContentContainerWrapper,
  ContentContainerAuthenticated,
  SubNavbar,
  SubNavbarOption,
  SubNavbarOptionText,
  PaymentOptions,
  PaymentOption,
  PaymentOptionLeft,
  PaymentOptionRight,
  MobileCreditContainer,
  MobileCreditContentWrapper,
  MobileCreditHeader,
  MobileCreditLogoContainer,
  MobileCreditOption,
  MobileCreditContinueButtonWrapper,
  MobileCreditPromoText,
  MobileCreditContinueButton,
  Footer as DigitalGoFooter,
  CheckboxContainer,
  TermsTextCredit,
  TermsTextCarrier,
} from 'components/digital-go/DigitalGoRedeemComponents'
import DigitalGoHeader from 'components/digital-go/DigitalGoHeader'
import Footer from 'components/Footer'
import { ITALIAN } from 'utils/supportedLanguages'
import {
  USER_AUTHENTICATED_QUERY_PARAM,
  trackRegistrationEvent,
  trackClickEvent,
} from 'utils/digitalGoAnalytics'
import logo from 'images/png/fw_vertical_logo_dark.png'

const getImages = graphql`
  {
    vodaphone: file(
      relativePath: {
        eq: "png/mobile_carrier/mobile_credit_carrier_vodaphone.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    windtre: file(
      relativePath: {
        eq: "png/mobile_carrier/mobile_credit_carrier_windtre.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`

const Redeem = () => {
  const CREDIT_CARD_PAYMENT_TYPE = 'CREDIT_CARD'
  const MOBILE_CREDIT_PAYMENT_TYPE = 'MOBILE_CREDIT'
  const MOBILE_CREDIT_VENDOR_VODAPHONE = 'VODAPHONE'
  const MOBILE_CREDIT_VENDOR_WINDTRE = 'WINDTRE'
  const FLUENTWORLDS_MONTHLY_NAME_EURO = 'FluentWorlds Monthly EUR'
  const AUTH0_LOCK_SIGN_UP = 'signUp'
  const DIGITAL_GO_HOME_PATH = '/digital-go/'
  const DIGITAL_GO_ACCOUNT_PATH = '/digital-go/account/'

  const {
    userStateLoading,
    fetchUserProductAccess,
    productAccess,
  } = useContext(UserContext)
  const {
    subscriptions,
    subscriptionStateLoading,
    fetchAvailableSitePlans,
    createStripeSubscriptionSession,
    getDigitalGoPaymentUrl,
  } = useContext(SubscriptionContext)
  const { user, isAuthenticated, loginWithRedirect, auth0Loading } = useAuth0()
  const { t, ready } = useTranslation('digitalGoRedeemPage')
  const [redirectToHomeFlag] = useQueryParam('account', StringParam)
  const [userAuthenticatedFlag] = useQueryParam('user-auth', StringParam)

  const data = useStaticQuery(getImages)
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    MOBILE_CREDIT_PAYMENT_TYPE,
  )
  const [selectedMobileCreditVendor, setSelectedMobileCreditVendor] = useState(
    MOBILE_CREDIT_VENDOR_WINDTRE,
  )
  const [isCheckedPrivacyDisclaimer, setIsCheckedPrivacyDisclaimer] = useState(
    false,
  )
  const [isRedirectLoading, setIsRedirectLoading] = useState(false)

  // once the user is authenticated, fetch their product access or redirect to sign-up if unauthenticated or redirect to appropriate page
  useEffect(() => {
    if (
      !auth0Loading &&
      !subscriptionStateLoading &&
      !userStateLoading &&
      isAuthenticated &&
      subscriptions &&
      subscriptions.length === 0
    ) {
      fetchAvailableSitePlans()
      if (!productAccess) {
        fetchUserProductAccess()
      }
    } else if (!auth0Loading && !isAuthenticated) {
      // redirect to the account page if the user has is unauthenticated
      setIsRedirectLoading(true)
      loginWithRedirect({
        redirect_uri:
          process.env.GATSBY_WEBSITE_URL +
          DIGITAL_GO_HOME_PATH +
          USER_AUTHENTICATED_QUERY_PARAM,
        mode: AUTH0_LOCK_SIGN_UP,
        ui_locales: ITALIAN.code,
      })
    } else if (
      !auth0Loading &&
      !subscriptionStateLoading &&
      !userStateLoading &&
      isAuthenticated &&
      productAccess &&
      productAccess.fluentworlds
    ) {
      setIsRedirectLoading(true)
      navigate(DIGITAL_GO_ACCOUNT_PATH)
    } else if (
      redirectToHomeFlag !== undefined &&
      redirectToHomeFlag &&
      redirectToHomeFlag === 'true'
    ) {
      // redirect to the account page if the user has is authenticated
      setIsRedirectLoading(true)
      navigate(DIGITAL_GO_ACCOUNT_PATH)
    }
  }, [
    auth0Loading,
    userStateLoading,
    subscriptionStateLoading,
    fetchAvailableSitePlans,
    fetchUserProductAccess,
    isAuthenticated,
    productAccess,
    subscriptions,
    loginWithRedirect,
    redirectToHomeFlag,
  ])

  useEffect(() => {
    if (
      userAuthenticatedFlag !== undefined &&
      userAuthenticatedFlag &&
      userAuthenticatedFlag === 'true' &&
      user
    ) {
      trackRegistrationEvent(user.sub)
    }
  }, [user, userAuthenticatedFlag])

  const isSubNavbarOptionSelected = (option) => {
    return option === selectedPaymentType ? true : false
  }

  const isVendorOptionSelected = (option) => {
    return option === selectedMobileCreditVendor ? true : false
  }

  const onClickSelectPaymentType = (paymentType) => {
    setSelectedPaymentType(paymentType)
  }

  const onClickSelectMobileCreditVendor = (vendor) => {
    setSelectedMobileCreditVendor(vendor)
  }

  // only allow Stripe Checkout flow when user is authenticated
  const onSubscriptionClick = async (subscriptionName) => {
    if (
      isAuthenticated &&
      productAccess &&
      !productAccess.fluentworlds &&
      isCheckedPrivacyDisclaimer === true
    ) {
      createStripeSubscriptionSession(subscriptionName)
    }
  }

  const onClickGetDigitalPaymentUrl = async (carrier) => {
    trackClickEvent('redeemAbbonati', user.sub)
    const carrierPaymentUrl = await getDigitalGoPaymentUrl(
      carrier.toLowerCase(),
    )

    if (typeof window !== 'undefined' && carrierPaymentUrl !== undefined) {
      setIsRedirectLoading(true)
      window.location.assign(carrierPaymentUrl)
    }
  }

  const handleChangeCheckboxPrivacyDisclaimer = (e) => {
    setIsCheckedPrivacyDisclaimer(!isCheckedPrivacyDisclaimer)
  }

  const showLoading =
    subscriptionStateLoading ||
    userStateLoading ||
    auth0Loading ||
    isRedirectLoading ||
    !productAccess ||
    !ready
  const showCreditCardSection = selectedPaymentType === CREDIT_CARD_PAYMENT_TYPE

  return (
    <>
      <Global styles={GlobalStyles} />
      <Helmet>
        <title>FluentWorlds</title>
      </Helmet>
      <DigitalGoHeader>
        <img src={logo} alt="fluentworlds.com" />
      </DigitalGoHeader>
      {showLoading ? (
        <Loading />
      ) : (
        <>
          <Body>
            <AllContentContainerWrapper>
              <ContentContainerAuthenticated>
                <SubNavbar>
                  <SubNavbarOption
                    isSelected={isSubNavbarOptionSelected(
                      CREDIT_CARD_PAYMENT_TYPE,
                    )}
                    onClick={() =>
                      onClickSelectPaymentType(CREDIT_CARD_PAYMENT_TYPE)
                    }
                  >
                    <GrCreditCard
                      onClick={() =>
                        onClickSelectPaymentType(CREDIT_CARD_PAYMENT_TYPE)
                      }
                    />
                    <SubNavbarOptionText>{t('creditCard')}</SubNavbarOptionText>
                  </SubNavbarOption>
                  <SubNavbarOption
                    isSelected={isSubNavbarOptionSelected(
                      MOBILE_CREDIT_PAYMENT_TYPE,
                    )}
                    onClick={() =>
                      onClickSelectPaymentType(MOBILE_CREDIT_PAYMENT_TYPE)
                    }
                  >
                    <FaSimCard
                      style={{ transform: 'rotate(270deg)  scaleY(-1)' }}
                    />
                    <SubNavbarOptionText>
                      {t('mobileCredit')}
                    </SubNavbarOptionText>
                  </SubNavbarOption>
                </SubNavbar>

                {showCreditCardSection ? (
                  // credit card section
                  <>
                    <PaymentOptions>
                      <PaymentOption>
                        <PaymentOptionLeft>
                          {t('subscriptionDurationOne')}
                        </PaymentOptionLeft>
                        <PaymentOptionRight>
                          {t('subscriptionPriceOne')}
                        </PaymentOptionRight>
                      </PaymentOption>
                    </PaymentOptions>
                    <MobileCreditContainer>
                      <MobileCreditContentWrapper />
                      <MobileCreditContinueButtonWrapper>
                        <MobileCreditPromoText>
                          {t('promoTextOne')}
                        </MobileCreditPromoText>
                        <MobileCreditPromoText>
                          {t('promoTextTwo')}
                        </MobileCreditPromoText>
                        <MobileCreditContinueButton
                          disabled={isCheckedPrivacyDisclaimer === false}
                          onClick={() =>
                            onSubscriptionClick(FLUENTWORLDS_MONTHLY_NAME_EURO)
                          }
                        >
                          {t('continueButton')}
                        </MobileCreditContinueButton>
                      </MobileCreditContinueButtonWrapper>
                    </MobileCreditContainer>
                  </>
                ) : (
                  // mobile credit section
                  <>
                    <PaymentOptions>
                      <PaymentOption>
                        <PaymentOptionLeft>
                          {t('subscriptionDurationOne')}
                        </PaymentOptionLeft>
                        <PaymentOptionRight>
                          {t('subscriptionPriceOne')}
                        </PaymentOptionRight>
                      </PaymentOption>
                    </PaymentOptions>

                    <MobileCreditContainer>
                      <MobileCreditContentWrapper>
                        <MobileCreditHeader>
                          {t('selectOperator')}
                        </MobileCreditHeader>
                        <MobileCreditLogoContainer>
                          <MobileCreditOption
                            isSelected={isVendorOptionSelected(
                              MOBILE_CREDIT_VENDOR_WINDTRE,
                            )}
                            onClick={() =>
                              onClickSelectMobileCreditVendor(
                                MOBILE_CREDIT_VENDOR_WINDTRE,
                              )
                            }
                          >
                            <GatsbyImage
                              image={
                                data.windtre.childImageSharp.gatsbyImageData
                              }
                              alt="windtre"
                            />
                          </MobileCreditOption>
                          <MobileCreditOption
                            isSelected={isVendorOptionSelected(
                              MOBILE_CREDIT_VENDOR_VODAPHONE,
                            )}
                            onClick={() =>
                              onClickSelectMobileCreditVendor(
                                MOBILE_CREDIT_VENDOR_VODAPHONE,
                              )
                            }
                          >
                            <GatsbyImage
                              image={
                                data.vodaphone.childImageSharp.gatsbyImageData
                              }
                              alt="vodaphone"
                            />
                          </MobileCreditOption>
                        </MobileCreditLogoContainer>
                      </MobileCreditContentWrapper>
                      <MobileCreditContinueButtonWrapper>
                        <MobileCreditPromoText>
                          {t('promoTextOne')}
                        </MobileCreditPromoText>
                        <MobileCreditPromoText>
                          {t('promoTextTwo')}
                        </MobileCreditPromoText>
                        <MobileCreditContinueButton
                          onClick={() =>
                            onClickGetDigitalPaymentUrl(
                              selectedMobileCreditVendor,
                            )
                          }
                        >
                          {t('continueButton')}
                        </MobileCreditContinueButton>
                      </MobileCreditContinueButtonWrapper>
                    </MobileCreditContainer>
                  </>
                )}
              </ContentContainerAuthenticated>

              {!showLoading &&
                (showCreditCardSection ? (
                  <DigitalGoFooter>
                    <CheckboxContainer>
                      <input
                        type="checkbox"
                        id="terms"
                        name="terms"
                        value="terms"
                        checked={isCheckedPrivacyDisclaimer}
                        onChange={handleChangeCheckboxPrivacyDisclaimer}
                      />
                    </CheckboxContainer>
                    <TermsTextCredit>
                      {t('creditCardFooterPrompt')}
                    </TermsTextCredit>
                  </DigitalGoFooter>
                ) : (
                  <DigitalGoFooter>
                    <TermsTextCarrier>
                      {t('mobileCreditFooterPrompt')}
                    </TermsTextCarrier>
                  </DigitalGoFooter>
                ))}
            </AllContentContainerWrapper>
          </Body>
        </>
      )}
      <Footer />
    </>
  )
}

export default Redeem
