export const ENGLISH = {
  code: 'en',
  label: 'English',
}

export const SPANISH = {
  code: 'es',
  label: 'Español',
}

export const TURKISH = {
  code: 'tr',
  label: 'Türkçe',
}

export const ITALIAN = {
  code: 'it',
  label: 'Italiano', // verify, not used for now
}

export const JAPANESE = {
  code: 'ja',
  label: '日本人', // verify, not used for now
}

export const PORTUGUESE = {
  code: 'pt',
  label: 'Português',
}
